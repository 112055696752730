@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';


.circles-wrapper {
  @include clearfix;

  margin: 18px 0 26px;
  text-align: center;
}
