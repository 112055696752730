{
  "aditional_requests": "Допълнителни бележки",
  "inside": "Вътре",
  "outside": "Вън",
  "personal_information": "Лична информация",
  "no_time_slots": "Няма свободни маси за това търсене.",
  "seating_tooltip": "Изеберете къде да бъдете настанени.",
  "seating_tooltip_inside": "Ресторантът има места само вътре",
  "select_date_and_guests": "Изберете дата и брой гости, за да видите възможните часове",
  "select_time": "Изберете час"
}
