import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import RequestService from '../../networking/RequestService';
import Place from '../../networking/resources/Place';
import ReviewCircleRating from './Reviews/ReviewCircleRating';
import Rating from './Rating';
import Reviews from './Reviews';

export default function ReviewsSection({
  restaurantId,
  name,
  restaurantInfo,
}) {
  const { t } = useTranslation();
  const [restaurantReviews, setRestaurantReviews] = useState([]);

  useEffect(() => {
    if (restaurantId) {
      (new RequestService('client/reviews/place'))
        .setParams({
          place_id: restaurantId,
          sort: 'submitted',
        })
        .send()
        .then((response) => {
          setRestaurantReviews(response.data);
        })
        .catch(() => null);
    }
  }, [restaurantId]);

  return (
    <section className="section">
      <h2 className="section-title">
        {`${t('pageRestaurantProfile:section_rating_title')} `}
        <strong>{name}</strong>
      </h2>
      <p>{t('pageRestaurantProfile:total_rating')}</p>
      <Rating
        stars={restaurantInfo.rateStars}
      />
      <small>{`(${restaurantInfo.rateCount} ${t('pageRestaurantProfile:ratings')})`}</small>
      {restaurantInfo.rateCount > 0 && (
        <div className={styles['circles-wrapper']}>
          <ReviewCircleRating
            title={t('pageRestaurantProfile:food')}
            rating={restaurantInfo.rateFood || 0}
          />
          <ReviewCircleRating
            title={t('pageRestaurantProfile:service')}
            rating={restaurantInfo.rateService || 0}
          />
          <ReviewCircleRating
            title={t('pageRestaurantProfile:ambience')}
            rating={restaurantInfo.rateAmbience || 0}
          />
        </div>
      )}
      <Reviews restaurantReviews={restaurantReviews} slug={restaurantInfo.slug} />
    </section>
  );
}

ReviewsSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
  restaurantInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]),
};

ReviewsSection.defaultProps = {
  restaurantId: '',
  name: '',
  restaurantInfo: {},
};
