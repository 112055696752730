import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import '../../../../../node_modules/react-circular-progressbar/dist/styles.css';

import * as styles from './style.module.scss';

export default function ReviewCircleRating({ title, rating }) {
  return (
    <div className={styles['circle']}>
      <CircularProgressbar
        value={rating}
        maxValue={5}
        text={rating}
        styles={{
          path: {
            strokeLinecap: 'butt',
          },
        }}
      />
      <p>{title}</p>
    </div>
  );
}

ReviewCircleRating.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};
