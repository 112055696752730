@import '../../../../react-web-ui/assets/styles/mixins';
@import '../../../../react-web-ui/assets/styles/variables';

.circle {
  padding: 0 12px;
  color: $awards-color;
  float: left;

  svg {
    width: 68px;
    height: 68px;
  }

  p {
    margin: 10px 0 0;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }


}

@include small {
  
  .circle {
    padding: 0 24px;
  }

}
