{
  "aditional_requests": "Richieste aggiuntive",
  "inside": "Dentro",
  "outside": "Fuori",
  "personal_information": "Informazioni personali",
  "no_time_slots": "Nessuna tabella disponibile per questa ricerca.",
  "seating_tooltip": "Scegli dove vuoi sedere",
  "seating_tooltip_inside": "Il ristorante offre solo posti all'interno",
  "select_date_and_guests": "Seleziona la data e il numero di invitati per visualizzare le fasce orarie",
  "select_time": "Scegli un orario"
}
