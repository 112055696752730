import Resource from './Resource';
import NeighbourhoodManager from './NeighbourhoodManager';
import ResourceCollection from './ResourceCollection';
import Cuisine from './Cuisine';
import Subscription from './Subscription';
import PayoutDetails from './PayoutDetails';
import Shift from './Shift';
import Salon from './Salon';

export default class PlaceManager extends Resource {
  static collectionKey = 'places';

  constructor(
    id,
    neighbourhood,
    name,
    subtitle,
    address,
    description,
    chefName,
    chefThumb,
    awardsList,
    translations,
    cuisines,
    slug,
    lat,
    lng,
    kidsPlace,
    parking,
    wifi,
    cardPay,
    petFriendly,
    isOwnershipVerified,
    contactEmail,
    contactPhoneNumber,
    notificationsLocale,
    rate,
    accountManager,
    facebookPageLink,
    youtubeChannelLink,
    instagramProfileLink,
    tripadvisorPageLink,
    googleId,
    subscriptions,
    acceptsGiftCards,
    areDiningEventsEnabled,
    areTastingMenusEnabled,
    payoutDetails,
    verificationRequestedAt,
    thumb,
    logo,
    images,
    priceRange,
    shifts,
    saveGuestsAutomatically,
    postalCode,
    salons,
    seed,
  ) {
    super(id);
    this._neighbourhood = neighbourhood;
    this._name = name;
    this._subtitle = subtitle;
    this._address = address;
    this._description = description;
    this._chefName = chefName;
    this._chefThumb = chefThumb;
    this._awardsList = awardsList;
    this._translations = translations;
    this._cuisines = cuisines;
    this._slug = slug;
    this._lat = lat;
    this._lng = lng;
    this._kidsPlace = kidsPlace;
    this._parking = parking;
    this._wifi = wifi;
    this._cardPay = cardPay;
    this._petFriendly = petFriendly;
    this._isOwnershipVerified = isOwnershipVerified;
    this._contactEmail = contactEmail;
    this._contactPhoneNumber = contactPhoneNumber;
    this._notificationsLocale = notificationsLocale;
    this._rate = rate;
    this._accountManager = accountManager;
    this._facebookPageLink = facebookPageLink;
    this._youtubeChannelLink = youtubeChannelLink;
    this._instagramProfileLink = instagramProfileLink;
    this._tripadvisorPageLink = tripadvisorPageLink;
    this._googleId = googleId;
    this._subscriptions = subscriptions;
    this._acceptsGiftCards = acceptsGiftCards;
    this._areDiningEventsEnabled = areDiningEventsEnabled;
    this._areTastingMenusEnabled = areTastingMenusEnabled;
    this._payoutDetails = payoutDetails;
    this._verificationRequestedAt = verificationRequestedAt;
    this._thumb = thumb;
    this._logo = logo;
    this._images = images;
    this._priceRange = priceRange;
    this._shifts = shifts;
    this._saveGuestsAutomatically = saveGuestsAutomatically;
    this._postalCode = postalCode;
    this._salons = salons;
    this._seed = seed;
  }

  get id() {
    return this._id;
  }

  get neighbourhood() {
    return this._neighbourhood;
  }

  get name() {
    return this._name;
  }

  get subtitle() {
    return this._subtitle;
  }

  get address() {
    return this._address;
  }

  get description() {
    return this._description;
  }

  get chefName() {
    return this._chefName;
  }

  get chefThumb() {
    return this._chefThumb;
  }

  get awardsList() {
    return this._awardsList;
  }

  get translations() {
    return this._translations;
  }

  get cuisines() {
    return this._cuisines;
  }

  get cuisinesText() {
    return this._cuisines.map((cuisine) => cuisine.name).join(', ');
  }

  get slug() {
    return this._slug;
  }

  get lat() {
    return this._lat;
  }

  get lng() {
    return this._lng;
  }

  get kidsPlace() {
    return this._kidsPlace;
  }

  get parking() {
    return this._parking;
  }

  get wifi() {
    return this._wifi;
  }

  get cardPay() {
    return this._cardPay;
  }

  get petFriendly() {
    return this._petFriendly;
  }

  get isOwnershipVerified() {
    return this._isOwnershipVerified;
  }

  get contactEmail() {
    return this._contactEmail;
  }

  get contactPhoneNumber() {
    return this._contactPhoneNumber;
  }

  get notificationsLocale() {
    return this._notificationsLocale;
  }

  get rate() {
    return this._rate;
  }

  get rateFood() {
    if (this._rate.food) {
      return parseFloat(this._rate.food.toFixed(1));
    }

    return 0;
  }

  get rateService() {
    if (this._rate.service) {
      return parseFloat(this._rate.service.toFixed(1));
    }

    return 0;
  }

  get rateAmbience() {
    if (this._rate.ambience) {
      return parseFloat(this._rate.ambience.toFixed(1));
    }

    return 0;
  }

  get rateStars() {
    return parseFloat(
      ((this._rate.ambience + this._rate.service + this._rate.food) / 3
      ).toFixed(1),
    );
  }

  get accountManager() {
    return this._accountManager;
  }

  get facebookPageLink() {
    return this._facebookPageLink;
  }

  get youtubeChannelLink() {
    return this._youtubeChannelLink;
  }

  get instagramProfileLink() {
    return this._instagramProfileLink;
  }

  get tripadvisorPageLink() {
    return this._tripadvisorPageLink;
  }

  get googleId() {
    return this._googleId;
  }

  get subscriptions() {
    return this._subscriptions;
  }

  get verificationRequestedAt() {
    return this._verificationRequestedAt;
  }

  get acceptsGiftCards() {
    return this._acceptsGiftCards;
  }

  get areDiningEventsEnabled() {
    return this._areDiningEventsEnabled;
  }

  get areTastingMenusEnabled() {
    return this._areTastingMenusEnabled;
  }

  get payoutDetails() {
    return this._payoutDetails;
  }

  get thumb() {
    return this._thumb;
  }

  get logo() {
    return this._logo;
  }

  get images() {
    return this._images;
  }

  get priceRange() {
    return this._priceRange;
  }

  get priceRangeText() {
    return `${this._priceRange.min}-${this._priceRange.max}`;
  }

  get shifts() {
    return this._shifts;
  }

  get saveGuestsAutomatically() {
    return this._saveGuestsAutomatically;
  }

  get postalCode() {
    return this._postalCode;
  }

  get salons() {
    return this._salons;
  }

  get seed() {
    return this._seed;
  }

  static fromAPI(object) {
    return new PlaceManager(
      object.id,
      NeighbourhoodManager.fromAPI(object.neighbourhood),
      object.name,
      object.subtitle,
      object.address,
      object.description,
      object.chef_name,
      object.chef_thumb,
      object.awards_list,
      object.translations,
      (new ResourceCollection(Cuisine)).make(object),
      object.slug,
      object.lat,
      object.lng,
      object.kids_place,
      object.parking,
      object.wifi,
      object.card_pay,
      object.pet_friendly,
      object.is_ownership_verified,
      object.contact_email,
      object.contact_phone_number,
      object.notifications_locale,
      object.rate,
      object.account_manager,
      object.facebook_page_link,
      object.youtube_channel_link,
      object.instagram_profile_link,
      object.tripadvisor_page_link,
      object.google_id,
      (new ResourceCollection(Subscription)).make(object),
      object.accepts_gift_cards,
      object.are_dining_events_enabled,
      object.are_tasting_menus_enabled,
      object.payout_details ? PayoutDetails.fromAPI(object.payout_details) : {},
      object.verification_requested_at,
      object.thumb,
      object.logo,
      object.images,
      object.price_range,
      (new ResourceCollection(Shift)).make(object),
      object.save_guests_automatically,
      object.postal_code,
      (new ResourceCollection(Salon)).make(object),
      object.seed,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      neighbourhood: NeighbourhoodManager.fromAPI(object.neighbourhood),
      name: object.name,
      subtitle: object.subtitle,
      address: object.address,
      description: object.description,
      chefName: object.chef_name,
      chefThumb: object.chef_thumb,
      awardsList: object.awards_list,
      translations: object.translations,
      cuisines: object.cuisines,
      slug: object.slug,
      lat: object.lat,
      lng: object.lng,
      kidsPlace: object.kids_place,
      parking: object.parking,
      wifi: object.wifi,
      cardPay: object.card_pay,
      petFriendly: object.pet_friendly,
      isOwnershipVerified: object.is_ownership_verified,
      contactEmail: object.contact_email,
      contactPhoneNumber: object.contact_phone_number,
      notificationsLocale: object.notifications_locale,
      rate: object.rate,
      accountManager: object.account_manager,
      facebookPageLink: object.facebook_page_link,
      youtubeChannelLink: object.youtube_channel_link,
      instagramProfileLink: object.instagram_profile_link,
      tripadvisorPageLink: object.tripadvisor_page_link,
      googleId: object.google_id,
      subscriptions: object.subscriptions,
      acceptsGiftCards: object.accepts_gift_cards,
      areDiningEventsEnabled: object.are_dining_events_enabled,
      areTastingMenusEnabled: object.are_tasting_menus_enabled,
      payoutDetails: object.payout_details || {},
      verificationRequestedAt: object.verification_requested_at,
      thumb: object.thumb,
      logo: object.logo,
      images: object.images,
      priceRangeText: `${object.price_range.min}-${object.price_range.max}`,
      shifts: object.shifts,
      saveGuestsAutomatically: object.save_guests_automatically,
      postalCode: object.postal_code,
      salons: object.salons,
      seed: object.seed,
    };
  }
}
