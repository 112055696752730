import React from 'react';
import PropTypes from 'prop-types';

const HalfStar = ({ className, testId }) => (
  <svg data-testid={testId} className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
    <defs>
      <clipPath id="a"><rect width="20" height="22" fill="currentColor" /></clipPath>
      <clipPath id="b"><rect width="10" height="22" fill="currentColor" /></clipPath>
    </defs>
    <g transform="translate(-96)">
      <g transform="translate(96)" clipPath="url(#a)"><path d="M100.106,623.844a.726.726,0,0,1,1.355,0l2.428,5.437a.764.764,0,0,0,.568.455l5.431.872a.777.777,0,0,1,.61.567.894.894,0,0,1-.191.856l-3.929,4.231a.887.887,0,0,0-.217.739l.927,5.976a.872.872,0,0,1-.3.823.7.7,0,0,1-.8.063l-4.858-2.821a.687.687,0,0,0-.7,0l-4.858,2.821a.7.7,0,0,1-.8-.063.872.872,0,0,1-.3-.823L95.406,637a.888.888,0,0,0-.217-.739l-3.93-4.231a.9.9,0,0,1-.19-.856.777.777,0,0,1,.61-.566l5.431-.872a.765.765,0,0,0,.568-.457Z" transform="translate(-91.015 -622.701)" fill="#E2E2EA" fillRule="evenodd" /></g>
      <g transform="translate(96)" clipPath="url(#b)"><path d="M100.106,623.844a.726.726,0,0,1,1.355,0l2.428,5.437a.764.764,0,0,0,.568.455l5.431.872a.777.777,0,0,1,.61.567.894.894,0,0,1-.191.856l-3.929,4.231a.887.887,0,0,0-.217.739l.927,5.976a.872.872,0,0,1-.3.823.7.7,0,0,1-.8.063l-4.858-2.821a.687.687,0,0,0-.7,0l-4.858,2.821a.7.7,0,0,1-.8-.063.872.872,0,0,1-.3-.823L95.406,637a.888.888,0,0,0-.217-.739l-3.93-4.231a.9.9,0,0,1-.19-.856.777.777,0,0,1,.61-.566l5.431-.872a.765.765,0,0,0,.568-.457Z" transform="translate(-91.019 -622.701)" fill="currentColor" fillRule="evenodd" /></g>
    </g>
  </svg>

);

HalfStar.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
};

HalfStar.defaultProps = {
  className: '',
  testId: '',
};

export default HalfStar;
