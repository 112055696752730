import moment from 'moment';
import DiscountsPlace from './DiscountsPlace';
import Gift from './Gift';
import Guest from './Guest';
import Place from './Place';

import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import SpecialEventForBooking from './SpecialEventForBooking';
import SpecialOfferClient from './SpecialOfferClient';
import Table from './Table';
import User from './User';

export default class Booking extends Resource {
  static collectionKey = 'bookings';

  constructor(
    id,
    contactName,
    contactEmail,
    contactPhone,
    dt,
    people,
    status,
    rateFood,
    rateService,
    rateAmbience,
    isLate,
    type,
    notes,
    createdAt,
    seatedOn,
    completedOn,
    resolution,
    voucher,
    gift,
    points,
    seen,
    os,
    place,
    tables,
    specialEvent,
    discount,
    specialOffer,
    users,
    pnr,
    owner,
    internalGuest,
    isDepositRequested,
  ) {
    super(id);
    this._contactName = contactName;
    this._contactEmail = contactEmail;
    this._contactPhone = contactPhone;
    this._dt = dt;
    this._people = people;
    this._status = status;
    this._rateFood = rateFood;
    this._rateService = rateService;
    this._rateAmbience = rateAmbience;
    this._isLate = isLate;
    this._type = type;
    this._notes = notes;
    this._createdAt = createdAt;
    this._seatedOn = seatedOn;
    this._completedOn = completedOn;
    this._resolution = resolution;
    this._voucher = voucher;
    this._gift = gift;
    this._points = points;
    this._seen = seen;
    this._os = os;
    this._place = place;
    this._tables = tables;
    this._specialEvent = specialEvent;
    this._discount = discount;
    this._specialOffer = specialOffer;
    this._users = users;
    this._pnr = pnr;
    this._owner = owner;
    this._internal_guest = internalGuest;
    this._is_deposit_requested = isDepositRequested;
  }

  get id() {
    return this._id;
  }

  get contactName() {
    return this._contactName;
  }

  get contactEmail() {
    return this._contactEmail;
  }

  get contactPhone() {
    return this._contactPhone;
  }

  get dt() {
    return this._dt;
  }

  get date() {
    const newDate = moment.unix(this._dt);
    return newDate.format('DD.MM.YYYY');
  }

  get time() {
    const newTime = moment.unix(this._dt).utc();
    return newTime.format('HH:mm');
  }

  get people() {
    return this._people;
  }

  get status() {
    return this._status;
  }

  get rateFood() {
    return this._rateFood;
  }

  get rateService() {
    return this._rateService;
  }

  get rateAmbience() {
    return this._rateAmbience;
  }

  get isLate() {
    return this._isLate;
  }

  get type() {
    return this._type;
  }

  get notes() {
    return this._notes;
  }

  get createdAt() {
    return this._createdAt;
  }

  get seatedOn() {
    return this._seatedOn;
  }

  get completedOn() {
    return this._completedOn;
  }

  get resolution() {
    return this._resolution;
  }

  get voucher() {
    return this._voucher;
  }

  get gift() {
    return this._gift;
  }

  get points() {
    return this._points;
  }

  get seen() {
    return this._seen;
  }

  get os() {
    return this._os;
  }

  get place() {
    return this._place;
  }

  get tables() {
    return this._tables;
  }

  get specialEvent() {
    return this._specialEvent;
  }

  get discount() {
    return this._discount;
  }

  get specialOffer() {
    return this._specialOffer;
  }

  get users() {
    return this._users;
  }

  get pnr() {
    return this._pnr;
  }

  get owner() {
    return this._owner;
  }

  get internalGuest() {
    return this._internal_guest;
  }

  get isDepositRequested() {
    return this._is_deposit_requested;
  }

  static fromAPI(object) {
    return new Booking(
      object.id,
      object.contact_name,
      object.contact_email,
      object.contact_phone,
      object.dt,
      object.people,
      object.status,
      object.rate_food,
      object.rate_service,
      object.rate_ambience,
      object.is_late,
      object.type,
      object.notes,
      object.created_at,
      object.seated_on,
      object.completed_on,
      object.resolution,
      object.voucher,
      object.gift !== null ? Gift.fromAPI(object.gift) : null,
      object.points,
      object.seen,
      object.os,
      Place.fromAPI(object.place),
      object.tables ? new ResourceCollection(Table).make(object) : [],
      object.special_event !== null
        ? SpecialEventForBooking.fromAPI(object.special_event)
        : null,
      object.discount ? DiscountsPlace.fromAPI(object.discount) : null,
      object.special_offer !== null
        ? SpecialOfferClient.fromAPI(object.special_offer)
        : null,
      object.users ? new ResourceCollection(User).make(object) : [],
      object.pnr,
      object.owner ? User.fromAPI(object.owner) : null,
      object.internal_guest ? Guest.fromAPI(object.internal_guest) : null,
      object.is_deposit_requested,
    );
  }
}
