@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.stars {
  display: inline-block;
  margin-right: 10px;

  .icon {
    width: auto;
    height: 22px;
    margin-right: 5px;
    fill: currentColor;
    color: $gray;
  }

  .filled {
    color: $awards-color;
  }

}
