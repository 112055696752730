{
  "aditional_requests": "Dodatkowe prośby",
  "inside": "W środku",
  "outside": "Na zewnątrz",
  "personal_information": "Dane osobowe",
  "no_time_slots": "Brak wolnych stolików o tej godzinie, sprawdź późniejsze terminy",
  "seating_tooltip": "Wybierz miejsce do siedzenia",
  "seating_tooltip_inside": "Restauracja oferuje wyłącznie",
  "select_date_and_guests": "Wybierz datę i liczbę gości, aby wyświetlić przedziały czasowe",
  "select_time": "Wybierz godzinę"
}
