{
  "aditional_requests": "Additional requests",
  "inside": "Inside",
  "outside": "Outside",
  "personal_information": "Personal information",
  "no_time_slots": "No tables available for this search.",
  "seating_tooltip": "Choose where you want to be seated.",
  "seating_tooltip_inside": "The restaurant offers only",
  "select_date_and_guests": "Select date and guests to show time slots",
  "select_time": "Select time"
}
