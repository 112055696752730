import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './style.module.scss';
import noAvatar from '../../../../react-web-ui/assets/images/no-avatar.png';

/**
 * Review item
 * @param className
 * @param avatar
 * @param username
 * @param reviewNumber
 * @param date
 * @param reviewText
 * @param reviewTip
 * @param helpful
 * @returns {*}
 * @constructor
 */
export default function ReviewItem({
  className,
  avatar,
  username,
  reviewNumber,
  date,
  reviewText,
  reviewTip,
}) {
  return (
    <div data-testid="review-item" className={`${styles['review-item']} ${className}`}>
      <header className={styles['review-header']}>
        <div className={styles['user-info']}>
          <div className={styles['avatar']}>
            <img
              src={avatar !== null ? avatar : noAvatar}
              alt={username}
              onError={(e) => {
                if (e.target.src !== noAvatar) {
                  e.target.src = noAvatar;
                }
              }}
            />
          </div>
          <div>
            <p>{username}</p>
            {reviewNumber > 0 && <span>{`${reviewNumber} ${reviewNumber > 1 ? 'reviews' : 'review'}`}</span>}
          </div>
        </div>
        <span>{date}</span>
      </header>
      <div className={styles['review-text']}>
        <p>{reviewText}</p>
        {reviewTip && <p className={styles['tip']}>{`Tip: ${reviewTip}`}</p>}
      </div>
    </div>
  );
}

ReviewItem.propTypes = {
  className: PropTypes.string,
  /** Avatar of the user. */
  avatar: PropTypes.string,
  /** Name of the user. */
  username: PropTypes.string.isRequired,
  /** Number of reviews of the user. */
  reviewNumber: PropTypes.number,
  /** Date of the review. */
  date: PropTypes.string.isRequired,
  /** Review text. */
  reviewText: PropTypes.string.isRequired,
  /** Avatar of the user. */
  reviewTip: PropTypes.string,
};

ReviewItem.defaultProps = {
  className: '',
  avatar: '',
  reviewNumber: 0,
  reviewTip: '',
};
