{
  "aditional_requests": "Dodatni zahtjevi",
  "inside": "Unutra",
  "outside": "Vani",
  "personal_information": "Osobni podaci",
  "seating_tooltip": "Odaberite mjesto na kojem želite sjediti.",
  "seating_tooltip_inside": "Restoran nudi samo sjedenje unutra",
  "select_date_and_guests": "Odaberite datum i goste za prikaz vremenskih odsječaka",
  "select_time": "Odaberite vrijeme"
}
