import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './style.module.scss';
import StarIcon from '../../SVGComponents/StarIcon';
import HalfStarIcon from '../../SVGComponents/HalfStar';

/**
 * Create rating element
 * @param stars
 * @returns {*}
 * @constructor
 */
export default function Rating({ stars }) {
  let allStars = parseFloat(stars);

  if (allStars >= 5) {
    return (
      <div data-testid="stars" className={styles['stars']}>
        <StarIcon testId="filled-star-1" className={`${styles['icon']} ${styles['filled']}`} />
        <StarIcon testId="filled-star-2" className={`${styles['icon']} ${styles['filled']}`} />
        <StarIcon testId="filled-star-3" className={`${styles['icon']} ${styles['filled']}`} />
        <StarIcon testId="filled-star-4" className={`${styles['icon']} ${styles['filled']}`} />
        <StarIcon testId="filled-star-5" className={`${styles['icon']} ${styles['filled']}`} />
      </div>
    );
  }
  if (allStars <= 0) {
    return (
      <div data-testid="stars" className={styles['stars']}>
        <StarIcon testId="gray-star-1" className={styles['icon']} />
        <StarIcon testId="gray-star-2" className={styles['icon']} />
        <StarIcon testId="gray-star-3" className={styles['icon']} />
        <StarIcon testId="gray-star-4" className={styles['icon']} />
        <StarIcon testId="gray-star-5" className={styles['icon']} />
      </div>
    );
  }

  const filledStars = [];
  const grayStars = [];
  const halfStars = [];

  if (stars % 1 !== 0) {
    halfStars.push(<HalfStarIcon key="half-start" testId="half-start" className={`${styles['icon']} ${styles['filled']}`} />);
    allStars += 1;
  }

  for (let i = 0; i < Math.floor(stars); i += 1) {
    filledStars.push(<StarIcon key={`filled-star-${i}`} testId={`filled-star-${i}`} className={`${styles['icon']} ${styles['filled']}`} />);
  }

  for (let j = 0; j < 5 - Math.floor(allStars); j += 1) {
    grayStars.push(<StarIcon key={`gray-star-${j}`} testId={`gray-star-${j}`} className={styles['icon']} />);
  }

  return (
    <div data-testid="stars" className={styles['stars']}>
      {filledStars}
      {halfStars}
      {grayStars}
    </div>
  );
}

Rating.propTypes = {
  /** Number of filled stars. */
  stars: PropTypes.number.isRequired,
};
